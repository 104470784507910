import React, { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { pxToRem } from '../utils/pxToRem';

import { ContentfulCategoryServices, ContentfulTeam, ContentfulTeamMember, Maybe } from '../model/generated/graphql';
import { motion, useSpring, useTransform, useViewportScroll } from 'framer-motion';
import { ThumbnailProps } from '../modules/movies/components/Gallery';
import { Swiper, SwiperSlide } from 'swiper/react';

const lorem =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

type ServicesPageProps = {
    data: {
        contentfulCategoryServices: ContentfulCategoryServices;
    };
};

import 'swiper/css';
import 'swiper/css/mousewheel';
import { Mousewheel } from 'swiper';
import { ROUTES } from '../utils/routes';
import LocaleLink from '../components/LocaleLink/LocaleLink';

export const Container = styled.div`
    width: ${pxToRem(1200)};
    margin: 0 auto;
`;

const Avatar = styled(GatsbyImage)`
    border-radius: 50%;
`;

export const PageTitle = styled.h1`
  font-size: ${pxToRem(60)};
  text-align: center;
  margin-bottom: 8rem};
`;

const ServicesPage: FC<ServicesPageProps & PageProps> = ({ data, location }) => {
    return (
        <div>
            <SliderContainer>
                <Swiper
                    modules={[Mousewheel]}
                    spaceBetween={0}
                    slidesPerView={2}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    mousewheel={true}
                    freeMode={{
                        enabled: true,
                        sticky: true,
                        momentumBounce: false,
                    }}
                    speed={1000}
                    // slidesPerGroup={1}
                    centeredSlides={true}
                    // autoHeight={true}
                >
                    {data.contentfulCategoryServices.services.map((el, i) => (
                        <SwiperSlide key={i}>
                            <LocaleLink to={`/${ROUTES.STUDIO}/${el.slug}`}>
                                <Thumbnail data={el.categoryImage} title={el.title} />{' '}
                            </LocaleLink>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </SliderContainer>
        </div>
    );
};

const SliderContainer = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
`;

export const Thumbnail: FC<ThumbnailProps> = ({ data, title, handleClick }) => {
    const image = getImage(data.gatsbyImageData);
    return (
        <ThumbnailWrapper>
            <motion.div onClick={handleClick} whileHover={{ scale: 1.1 }}>
                <GatsbyImage alt="" image={image} />

                <ThumbnailTitle>{title}</ThumbnailTitle>
            </motion.div>
        </ThumbnailWrapper>
    );
};

const ThumbnailTitle = styled.h2`
    font-size: 3rem;
    color: #fff;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-100%);
`;

const ScrollContainer = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    will-change: transform;
`;

const Ghost = styled.div`
    width: 100vw;
`;

const ThumbnailsContainer = styled(motion.div)`
    position: relative;
    width: max-content;
    display: flex;
    align-items: center;
    //margin-left: 30vw;
    padding: 300px calc(50vw - 300px) 0 calc(50vw - 300px);
    column-gap: 200px;
    gap: 100px;
    max-height: 50vh;
`;

const Thumbnails = styled.div`
    position: relative;
    display: flex;
    & > *:not(:last-child) {
        margin-right: 250px;
    }
`;

const ThumbnailWrapper = styled.div`
    text-align: center;
    position: relative;
    //max-height: 90vh;
    //width: 30vw;
    //@media (max-width: 480px) {
    //    width: 80vw;
    //}
    //max-width: 600px;
    border-radius: 0.75rem;
    overflow: hidden;
`;

export default ServicesPage;

export const pageQuery = graphql`
    query ServicesQuery($node_locale: String) {
        contentfulCategoryServices(node_locale: { eq: $node_locale }, contentful_id: { eq: "s1uBfNCpSDHkrRmPAjnJM" }) {
            title
            services {
                title
                categoryImage {
                    gatsbyImageData(layout: CONSTRAINED, width: 1000, height: 1000, cropFocus: CENTER)
                }
                slug
            }
        }
    }
`;
