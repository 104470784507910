import React, { FC, MouseEventHandler, useContext } from 'react';
import { Link } from 'gatsby';

import LocaleContext from '../../context/Locale';
import styled from 'styled-components';

interface Props {
    to: string;
    onMouseEnter?: MouseEventHandler<HTMLAnchorElement>;
    onMouseLeave?: MouseEventHandler<HTMLAnchorElement>;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    children: React.ReactNode;
}

const LocaleLink: FC<Props> = ({ children, to, ...props }) => {
    const { activeLocale } = useContext(LocaleContext);

    const path = to === `/` ? `/${activeLocale.toLowerCase()}` : `/${activeLocale.toLowerCase()}${to}`;

    return (
        <StyledLink {...props} to={path}>
            {children}
        </StyledLink>
    );
};

const StyledLink = styled(Link)`
    color: var(--text-color-primary);
`;

export default LocaleLink;
